<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Helper v-model="productHelper" header="Buscar Producto" :headers="productHeaders" :rows="products" @selected="selectProduct" />
        <Helper v-model="MovementWarehouseHelper" header="Buscar Link de Pago" :headers="MovementWarehouseHeaders" :rows="entities" @selected="selectEntity" />
        <Toast />
        <div class="col-12">
            <div class="card">
                <Panel header="Movimientos de Almacen">
                    <BasicFormToolbar @new="newMovementWarehouse" @cancel="cancel" @save="save" @search="MovementWarehouseHelper.visible = true" :actions="['save', 'new', 'search', 'refresh', 'cancel']" />
                    <TabView>
                        <TabPanel header="Informacion General">
                            <div>
                                <Fieldset :toggleable="true" legend="Informacion del Movimiento ">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-3 xl:col-3">
                                            <label for="Fecha">Fecha Movimiento</label>
                                            <FormCalendar disabled="true" dateFormat="dd/mm/yy" v-model="entity.date_mov" :showIcon="true" />
                                        </div>
                                        <FormDropdownComplex
                                            :labelInOption="'id - name'"
                                            :labelInValue="'id - name'"
                                            :wrapperClass="'field col-12 md:col-3 xl:col-3'"
                                            label="Tipo de Movimiento"
                                            v-model="entity.id_movementtype"
                                            :options="id_movementtype"
                                            optionLabel="name"
                                            optionValue="id"
                                            :tooltip="'Aparece al llenar Grupo'"
                                        />

                                        <FormDropdownComplex
                                            :labelInOption="'id - name'"
                                            :labelInValue="'id - name'"
                                            :wrapperClass="'field col-12 md:col-3 xl:col-3'"
                                            label="Movimiento"
                                            v-model="entity.id_movement"
                                            :options="id_movement"
                                            optionLabel="name"
                                            optionValue="id"
                                            :tooltip="'Aparece al llenar Grupo'"

                                        />
                                        <FormInputText :textArea="true" v-model="entity.comment" label="Commentarios" wrapperClass="field col-12 xl:col-3" :valid="validate.validations.comment" />
                                    </div>
                                </Fieldset>
                                <br />
                            </div>

                            <br />
                            <Fieldset :toggleable="true" legend="Items">
                                <BasicFormToolbar :actions="['save', 'new']" @new="newItem" @save="saveItem" />
                                <div class="p-fluid formgrid grid">
                                    <FormInputText
                                        :search="true"
                                        ref="sku"
                                        v-model="InventoryMovementWarehouseItems.id_article"
                                        label="SKU"
                                        wrapperClass="field col-12 xl:col-2"
                                        :valid="itemValidation.validations.id_article"
                                        @search="productHelper.visible = true"
                                    />
                                    <FormInputText v-model="InventoryMovementWarehouseItems.productName" label="Descripcion" wrapperClass="field col-12 xl:col-2" :valid="itemValidation.validations.productName" />
                                    <FormDropdownComplex
                                        :labelInOption="'id - name'"
                                        :labelInValue="'id - name'"
                                        :wrapperClass="'field col-12 md:col-4 xl:col-2'"
                                        label="Almacen"
                                        v-model="InventoryMovementWarehouseItems.id_warehouse"
                                        :options="id_warehouse"
                                        optionLabel="name"
                                        optionValue="id"
                                        :tooltip="'Aparece al llenar Grupo'"
                                    />
                                    <FormDropdownComplex
                                        :labelInOption="'id - name'"
                                        :labelInValue="'id - name'"
                                        :wrapperClass="'field col-12 md:col-4 xl:col-2'"
                                        label="SubAlmacen"
                                        v-model="InventoryMovementWarehouseItems.id_subwarehouse"
                                        :options="id_subwarehouse"
                                        optionLabel="name"
                                        optionValue="id"
                                        :tooltip="'Aparece al llenar Grupo'"
                                    />

                                    <FormInputNumber
                                        v-model="InventoryMovementWarehouseItems.quantity"
                                        label="Cantidad"
                                        wrapperClass="field col-12 xl:col-2"
                                        :valid="itemValidation.validations.quantity"
                                        validation="Fabor de llenar el campo, la cantidad debe ser mayor a 0"
                                    />
                                    <FormInputNumber
                                        v-model="InventoryMovementWarehouseItems.coust"
                                        label="Precio"
                                        wrapperClass="field col-12 xl:col-2"
                                        :valid="itemValidation.validations.coust"
                                        :disabled="true"
                                        @tab="saveItem($event)"
                                        @enter="saveItem($event)"
                                        validation="Favor de llenar el campo, el precio debe ser mayor a $0.00"
                                    />
                                    <div class="col-12">
                                        <h3>Items agregados para su guardado</h3>
                                        <BasicDatatable @selected="selectItem" :headers="itemHeaders" :rowaction="true" :rowdelete="true" :selectionMode="'single'" :dataKey="'number'" @deleted="deleteItem" :rows="entity.items" />
                                        <h3>Stock de Inventario</h3>
                                        <BasicDatatable @selected="selectItem" :headers="inventaryHeaders" :rowaction="true"  :selectionMode="'single'" :dataKey="'number'"  :rows="movimientos" />
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>
                    </TabView>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
import Loader from '../../../components/general/Loader.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';
import FormCalendar from '../../../components/general/FormCalendar.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import sessionMixin from '../../../mixins/sessionMixin';
import { InventoryMovementWarehouse } from '../../../models/inventarios/InventoryMovementWarehouse';
import { InventoryMovementWarehouseItems } from '../../../models/inventarios/InventoryMovementWarehouseItems';
import { InventoryMovementWarehouseUEPS } from '../../../models/inventarios/InventoryMovementWarehouseUEPS';
import { InventoryMovementWarehouseItemsUEPS } from '../../../models/inventarios/InventoryMovementWarehouseItemsUEPS';
import { ErrorToast, fillObject, HeaderGrid, Rule, Toast, validate } from '../../../utilities/General';
import { InventoryArticle } from '../../../models/inventarios/InventoryArticle';
import { InventoryWarehouse } from '../../../models/inventarios/InventoryWarehouse';
import { InventorySubWarehouse } from '../../../models/inventarios/InventorySubWarehouse';
import { Movement_Type } from '../../../models/inventarios/MovementType';
import { InventoryMovement } from '../../../models/inventarios/InventoryMovement';
import { InventoryConfiguration } from '../../../models/inventarios/InventoryConfiguration';

var math = require('mathjs');
export default {
    mixins: [sessionMixin],
    props: {
        id: null,
    },
    data() {
        return {
            entity: new InventoryMovementWarehouse(this.session),
            entityUEPS: new InventoryMovementWarehouseUEPS(this.session),
            InventoryMovementWarehouseItems: new InventoryMovementWarehouseItems(this.session),
            InventoryMovementWarehouseItemsUEPS: new InventoryMovementWarehouseItemsUEPS(this.session),

            loading: false,
            MovementWarehouseHelper: {
                visible: false,
            },
            productHelper: {
                visible: false,
            },
            movimientos: [],
            products: [],
            MovementWarehouseHeaders: [new HeaderGrid('#', 'id'), new HeaderGrid('Tipo de Movimiento', 'id_movementtype'), new HeaderGrid('Movimiento', 'id_movement'), new HeaderGrid('Fecha de Movimiento', 'date_mov', { type: 'date' })],
            productHeaders: [new HeaderGrid('SKU', 'barcode'), new HeaderGrid('Nombre', 'name'), new HeaderGrid('P.U.', 'unit_price', { type: 'currency' })],
            headers: [
                new HeaderGrid('ID', 'id'),
                new HeaderGrid('Cliente', 'customerInformation_name'),
                new HeaderGrid('Descripcion', 'MovementWarehouseInformation_description'),
                new HeaderGrid('Total a Pagar', 'orderInformation_amountDetails_totalAmount', { type: 'currency' }),
                new HeaderGrid('Fecha de Importe Efectuado', 'created', { type: 'date' }),
                new HeaderGrid('Dias Pendientes', 'dias_pendientes', { formula: 'today_difference', expression: 'MovementWarehouseInformation_dueDate' }),
                new HeaderGrid('Saldo Pagado', 'auth_amount', { type: 'currency', function: 'SUM' }),
                // new HeaderGrid('Saldo Pendiente', 'sal_pendiente',{type: 'currency', function: 'SUM'}),
                //  new HeaderGrid('Estatus', 'status')
            ],

            itemValidation: {
                valid: false,
                validations: {
                    id_article: null,
                    productName: null,
                    quantity: null,
                    id_warehouse: null,
                    id_subwarehouse: null,
                    coust: null,
                },
            },

            itemRules: [
                new Rule({ name: 'id_article' }),
                new Rule({ name: 'productName' }),
                new Rule({ name: 'id_warehouse' }),
                new Rule({ name: 'id_subwarehouse' }),
                new Rule({ name: 'quantity', value: 'number', type: 'min', min: 0 }),
                new Rule({ name: 'coust', value: 'number', type: 'min', min: 0 }),
            ],
            rules: [new Rule({ name: 'date_mov' }), new Rule({ name: 'id_movementtype' }), new Rule({ name: 'id_movement' }), new Rule({ name: 'comment' })],
            validate: {
                valid: false,
                validations: {
                    date_mov: null,
                    id_movementtype: null,
                    id_movement: null,
                    comment: null,
                },
            },
            itemHeaders: [
                new HeaderGrid('#', 'number'),
                new HeaderGrid('SKU', 'id_article'),
                new HeaderGrid('Nombre', 'productName'),
                new HeaderGrid('Cantidad', 'quantity', { editable: true }),
                new HeaderGrid('Precio', 'coust', { type: 'currency', editable: true }),
                new HeaderGrid('Total', 'total', { type: 'currency', formula: 'evaluate', function: 'SUM', expression: 'coust * quantity' }),
            ],
            inventaryHeaders: [new HeaderGrid('Almacen', 'strwarehouse'), new HeaderGrid('SubAlmacen', 'strsubwarehouse'), new HeaderGrid('SKU', 'id_article'), new HeaderGrid('Nombre', 'strarticle'), new HeaderGrid('Cantidad', 'quantity')],
            c_Moneda: [],
            entities: [],
            MovementWarehousePays: [],
            id_warehouse: [],
            id_subwarehouse: [],
            id_movementtype: [],
            id_movement: [],
        };
    },

    computed: {
        SUMATO() {
            return this.subtotal + this.impuestos - this.entity.orderInformation_amountDetails_discountAmount + this.entity.orderInformation_freight_amount;
        },
        total() {
            return this.MovementWarehousePays.reduce((a, b) => {
                return math
                    .chain(a)
                    .add(b.auth_amount ?? 0.0)
                    .done();
            }, 0);
        },
        TOTALAP() {
            return this.SUMATO - this.total;
        },
        subtotal() {
            let subtotal = this.entity.items.reduce((a, b) => {
                var itemSubtotal = math.chain(b.quantity).multiply(b.unitPrice).done();
                return math.chain(a).add(itemSubtotal).done();
            }, 0);
            return subtotal ? subtotal : 0.0;
        },
        impuestos() {
            if (this.subtotal == 0 || this.entity.orderInformation_taxDetails_rate == null || this.entity.orderInformation_taxDetails_rate < 0 || this.entity.orderInformation_taxDetails_rate == 0) {
                return 0;
            } else {
                let percentage = math.chain(this.entity.orderInformation_taxDetails_rate).divide(100).done();
                return math.chain(this.subtotal).multiply(percentage).done();
            }
        },
    },
    methods: {
        selectItem(item) {
            this.InventoryMovementWarehouse = fillObject(this.InventoryMovementWarehouse, item);
        },
        copyLink() {
            if (this.entity.paymentLink) {
                navigator.clipboard.writeText(this.entity.paymentLink);
                this.$toast.add(
                    new Toast({
                        summary: 'Link de Pago',
                        detail: 'Se ha copiado el link en el portapapeles',
                    })
                );
            }
        },
        sendWhatsapp() {
            if (this.entity.paymentLink) {
                window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(this.entity.paymentLink));
            }
        },
        searchProduct() {
            let product = this.products.find((x) => x.barcode == this.InventoryMovementWarehouse.barcode);
            console.log(product);
            if (product) this.selectProduct(product);
        },
        newMovementWarehouse() {
            this.entity = new InventoryMovementWarehouse(this.session);
            this.InventoryMovementWarehouse = new InventoryMovementWarehouseItems(this.session);
        },
        async selectEntity(MovementWarehouse) {
            console.log(MovementWarehouse);
            this.entity = fillObject(this.entity, MovementWarehouse);
        },
        async refresh() {
            this.loading = true;
            try {
                this.id_movement = await new InventoryMovement(this.session).all();
                this.entity = new InventoryMovementWarehouse(this.session);
                this.entityUEPS = new InventoryMovementWarehouseUEPS(this.session);
                this.InventoryMovementWarehouseItems = new InventoryMovementWarehouseItems(this.session);
                this.products = await new InventoryArticle(this.session).all();
                this.entities = await new InventoryMovementWarehouse(this.session).getCustom();
                console.log(this.entities);
                this.id_warehouse = await new InventoryWarehouse(this.session).all();
                console.log(this.id_warehouse);
                this.id_subwarehouse = await new InventorySubWarehouse(this.session).getCustom();
                console.log(this.id_subwarehouse);
                this.id_movementtype = await new Movement_Type(this.session).all();
                this.entity.date_mov = new Date();

                       this.configuration = await new InventoryConfiguration(this.session).getCustom();
                var conf = this.configuration[0].value;
                if(conf==1){
                this.movimientos = await new InventoryMovementWarehouse(this.session).getInventary();
                console.log(this.movimientos.id_article);
                }
                else {
                       this.movimientos = await new InventoryMovementWarehouseUEPS(this.session).getInventary();
                }
                if (this.id) {
                    this.entity.id = this.id;
                    this.InventoryMovementWarehouse.id = this.id;
                    let retrieved = await this.entity.retrieve();
                    this.entity = fillObject(this.entity, retrieved);
                    this.entity.items = await this.entity.retrieveItem();
                    this.entity.MovementWarehouseInformation_dueDate = new Date(this.entity.MovementWarehouseInformation_dueDate);
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },

        deleteItem(item) {
            try {
                if (item.id) throw 'No se puede eliminar una partida ya guardada';

                let number = item.number;
                this.entity.items = this.entity.items.filter((x) => x.number != item.number);
                this.entity.items.forEach((x) => {
                    if (x.number > number) {
                        x.number = x.number - 1;
                    }
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        selectProduct(product) {
            console.log(product.barcode);
            this.InventoryMovementWarehouseItems.id_article = product.barcode;
            this.InventoryMovementWarehouseItems.productName = product.name;
            this.InventoryMovementWarehouseItems.coust = product.unit_price;
            this.productHelper.visible = false;
        },
        async save() {
            this.loading = true;
            try {
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) throw 'Favor de validar los campos';
                if (this.entity.id) {
                    let MovementWarehouse = await this.entity.update();
                    let index = this.entities.findIndex((x) => x.id == MovementWarehouse.id);
                    this.entity.items = MovementWarehouse.items;
                    this.entities[index] = {
                        ...this.entity,
                    };
                } else {
                    //Buscamos si ya existe
                    // if (this.entities.find(x => x.MovementWarehouseInformation_number == this.entity.MovementWarehouseInformation_number))
                    //     throw "Ya existe un link de pago con el mismo folio, favor de cambiar el folio del registro actual";

                    let MovementWarehouse = await this.entity.Mov();
                   this.entityUEPS.date_mov=this.entity.date_mov;
                   this.entityUEPS.id_movementtype=this.entity.id_movementtype;
                   this.entityUEPS.id_movement=this.entity.id_movement;
                   this.entityUEPS.comment=this.entity.comment;
                   this.entityUEPS.items=this.entity.items

                   
                   
                   
                   
                   
                   let MovementWarehouseUEPS=await this.entityUEPS.Mov();
                    this.entities.push(MovementWarehouse);
                    this.entity.id = MovementWarehouse.id;
                    this.entity.items = MovementWarehouse.items;
                    console.log(MovementWarehouseUEPS);
                }
                this.$toast.add(
                    new Toast({
                        summary: 'Movimiento',
                        detail: 'Informacion guardada con exito',
                    })
                );
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        newItem() {
            this.InventoryMovementWarehouseItems = new InventoryMovementWarehouseItems(this.session);
            this.itemValidation.valid = true;
            this.itemValidation.validations;
            //* Limpiar validaciones
            Object.keys(this.itemValidation.validations).forEach((prop) => (this.itemValidation.validations[prop] = null));
        },
        async saveItem() {
            this.validate = validate(this.entity, this.rules);
            if (!this.validate.valid) throw 'Favor de validar los campos de infromacion de movimiento';
            this.itemValidation = validate(this.InventoryMovementWarehouseItems, this.itemRules);
            if (!this.itemValidation.valid) {
                if (event) {
                    event.preventDefault();
                }
                throw 'Favor de validar los campos';
            }

            var stock = await new InventoryMovementWarehouse(this.session).getInventaryxArticle(this.InventoryMovementWarehouseItems.id_warehouse, this.InventoryMovementWarehouseItems.id_subwarehouse, this.InventoryMovementWarehouseItems.id_article);
            console.log(stock.quantity);
            if (this.entity.id_movementtype == 1002) {
                if (stock.quantity != null) {
                    if (stock.quantity >= this.InventoryMovementWarehouseItems.quantity) {
                        if (this.InventoryMovementWarehouseItems.number) {
                            let index = this.entity.items.findIndex((x) => x.number == this.InventoryMovementWarehouseItems.number);
                            this.entity.items[index] = { ...this.InventoryMovementWarehouseItems };
                        } else {
                            this.InventoryMovementWarehouseItems.number = this.InventoryMovementWarehouseItems.number ? this.InventoryMovementWarehouseItems.id : this.entity.items.length + 1;
                            this.entity.items.push({
                                ...this.InventoryMovementWarehouseItems,
                            });
                        }

                        this.InventoryMovementWarehouseItems = new InventoryMovementWarehouseItems(this.session);
                        this.$refs.sku.focus();

                        this.$toast.add(
                            new Toast({
                                summary: ' Item',
                                detail: 'Item agregado a lista',
                            })
                        );
                    } else {
                        this.$toast.add(new ErrorToast('No agregado porque la cantidad de articulo es mayor al stock'));
                    }
                } else {
                    this.$toast.add(new ErrorToast('No hay stock de este articulo'));
                }
            } else {
                if (this.InventoryMovementWarehouseItems.number) {
                    let index = this.entity.items.findIndex((x) => x.number == this.InventoryMovementWarehouseItems.number);
                    this.entity.items[index] = { ...this.InventoryMovementWarehouseItems };
                } else {
                    this.InventoryMovementWarehouseItems.number = this.InventoryMovementWarehouseItems.number ? this.InventoryMovementWarehouseItems.id : this.entity.items.length + 1;
                    this.entity.items.push({
                        ...this.InventoryMovementWarehouseItems,
                    });
                }

                this.InventoryMovementWarehouseItems = new InventoryMovementWarehouseItems(this.session);
                this.$refs.sku.focus();
            }
        },
        async cancel() {
            // this.loading = true;
            // try {
            //     let response = await this.entity.delete();
            //     console.log(response);
            // } catch (error) {
            //     this.$toast.add(new ErrorToast(error));
            // } finally {
            //     this.loading = false;
            // }
        },
    },
    async mounted() {
        await this.refresh();
        this.id_movementtype.push(
            {
                id: 1001,
                name: 'Entrada',
            },
            {
                id: 1002,
                name: 'Salida',
            }
        );
    },
    components: {
        Loader,
        BasicDatatable,
        FormInputText,
        FormInputNumber,
        BasicFormToolbar,
        FormDropdownComplex,
        FormCalendar,
        Helper,
    },
};
</script>

<style>
</style>